.works-table-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (orientation: portrait) {
    height: 100svh;
  }
  .works-table {
    border-color: $color-primary;
    width: 100%;
    text-align: left;

    @media (orientation: portrait) {
      padding: 0 10px;
    }
    // th {
    //   @include rfs(font-size, 20); // Convertito 1.25rem in px
    //   line-height: 1.2;
    //   font-weight: inherit;
    // }

    // td {
    //   @include rfs(font-size, 28); // Convertito 1.75rem in px
    //   font-size: 2.5vh; // Lasciato come è
    //   line-height: 1.22;
    //   line-height: 3.5vh; // Lasciato come è
    //   font-weight: inherit;
    // }

    .work {
      pointer-events: none;
      &.visitable {
        pointer-events: all;
        cursor: pointer;
        .work-discover-more {
          opacity: 1;
        }
        &:hover {
          td {
            opacity: 0.4;
          }
        }
      }
      &:hover {
        .hovered-image {
          opacity: 1;
        }
      }
    }

    th,
    td {
      &:not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(7)) {
        @media (orientation: portrait) {
          display: none;
        }
      }
    }

    th,
    td {
      display: flex;
      flex: 1;
      font-weight: 500;
      p {
        font-weight: inherit;
      }
      &.work-discover-more {
        flex: none;
        opacity: 0;
        a {
          cursor: default;
        }
      }
    }
    tr {
      display: flex;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $color-primary;
    }
    tbody {
      tr {
        @include rfs(padding-top, 10);
        @include rfs(padding-bottom, 10);
      }
    }

    td {
      transition: $transition;
      > div {
        max-width: 90%;
      }
    }

    .work-title {
      width: 24%;
    }

    .work-discover-more {
      text-align: right;
    }

    .hovered-image {
      width: rvw(350);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
  }

  .work-filters {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
}
