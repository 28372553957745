$transition: all 0.3s ease-in-out;
.height {
  &--100 {
    height: 100%;
  }
  &--100vh {
    height: 100vh;
    height: 100svh;
    @media (orientation: portrait) {
      height: auto;
      padding: 50px 0;
      box-sizing: content-box;
    }
  }
}

.width {
  &--100 {
    width: 100%;
  }
  &--100vw {
    width: 100vw;
  }
  &--1800 {
    width: rvw(1800);
  }
  &--1600 {
    width: rvw(1600);
  }
  &--1500 {
    width: rvw(1500);
  }
  &--1370 {
    width: rvw(1370);
  }
  &--1300 {
    width: rvw(1300);
  }
  &--1100 {
    width: rvw(1100);
  }
  &--1000 {
    width: rvw(1000);
  }
  &--1800,
  &--1600,
  &--1500,
  &--1370,
  &--1300,
  &--1100,
  &--1000 {
    margin: 0 auto;
    @media screen and (orientation: portrait) {
      width: 85vw !important;
    }
  }
}

.flex {
  &-30 {
    flex: 0 0 30% !important;
  }
  &-50 {
    flex: 0 0 50% !important;
  }
  &-85 {
    flex: 0 0 85% !important;
    @media screen and (orientation: portrait) {
      flex: 0 0 100% !important;
    }
  }
  &-none {
    flex: none !important;
  }
}

.max-height-100-vh {
  max-height: 100vh;
}

.height-100-vh {
  height: 100vh;
}

.width--75 {
  width: 75%;
  @media (orientation: portrait) {
    width: 100%;
  }
}
.width--50 {
  width: 50%;
  @media (orientation: portrait) {
    width: 100%;
  }
}
.width--30vw {
  width: 30vw;
  @media (orientation: portrait) {
    width: 100%;
  }
}
.width--20vw {
  width: 20vw;
  @media (orientation: portrait) {
    width: 100%;
  }
}

.width--100 {
  width: 100%;
}

.justify-content {
  &-start {
    justify-content: flex-start !important;
  }
  &-center {
    justify-content: center !important;
  }
}
.flex {
  &-none {
    flex: none !important;
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.max-width-initial {
  width: initial;
  max-width: initial;
}

.jc {
  justify-content: center;
}

.of-hidden {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.mx-auto {
  margin: 0 auto;
}

.opacity-0 {
  opacity: 0 !important;
}

.position--absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.position--absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d-none {
  display: none !important;
}

.position--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.position--fixed-center {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.position--relative {
  position: relative;
}

.height--100 {
  height: 100%;
}

.title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p {
  @media (orientation: landscape) {
    &l {
      &-100 {
        padding-left: rvw(100) !important;
      }
    }
    &r {
      &-100 {
        padding-right: rvw(100) !important;
      }
    }
  }
}

.gap {
  &--100 {
    @include rfs(gap, 100);
  }
}

.translated-block-20 {
  @media (orientation: landscape) {
    transform: translateY(-20%);
  }
}

.bg {
  &--type {
    &-0 {
      background-repeat: no-repeat;
      background-size: cover;
    }
    &-1 {
      background-repeat: no-repeat;
      background-size: cover;
    }
    &-10 {
      background: none;
    }
  }
}
