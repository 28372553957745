.contact-form {
  form {
    @media (orientation: portrait) {
      gap: 10px 0;
      flex-direction: column;
      display: flex;
    }
    .input-checkbox-wrap {
      display: flex;
      align-items: baseline;
      user-select: none;
      max-width: fit-content;
    }
    input {
      &[type='email'] {
        @include rfs(width, 500);
        padding: 15px;
        @extend .t-26;
        @media (orientation: portrait) {
          width: -webkit-fill-available;
        }
      }
      &[type='checkbox'] {
        cursor: pointer;
        margin-right: 10px;
        width: 15px;
        height: 15px;
      }
    }
    button[type='submit'] {
      cursor: pointer;
      @include rfs(width, 200);
      @extend .t-26;
      background-color: $color-primary;
      color: $white;
      text-align: center;
      padding: 10px 15px;
      border: none;
      @media (orientation: portrait) {
        width: 100%;
      }
    }
    label {
      @extend .t-20;
      cursor: pointer;
    }
  }
}
