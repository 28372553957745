.page.single-project {
  .single-project-hero {
    display: block !important;
  }
}

.single-project-hero {
  height: 100vh;
  height: 100svh;
  position: relative;
  .fp-overflow {
    height: 100%;
  }
  // single-project-hero fullscreen-image height--100vh section fp-section active fp-table fp-is-overflow fp-completely
  // single-project-hero fullscreen-image height--100vh section
  &[data-single-page-enabled='false'] {
    display: none;
  }
  @media (orientation: portrait) {
    box-sizing: border-box;
  }
  .single-project-hero-inner {
    @include rfs(
      padding-bottom,
      calc($menuItemDistanceV * 4)
    ); // Convertito 9rem in px
    @include rfs(padding-left, $menuItemDistanceH); // Convertito 18rem in px
    // @include rfs(padding-right, 18); // Convertito 18rem in px
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    @media (orientation: portrait) {
      box-sizing: border-box;
    }

    h2 {
      color: $color-accent-active;
      position: relative;
      text-transform: uppercase;
      z-index: 3;

      &.clickable {
        cursor: pointer;
      }
    }
  }
  &__imageWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.25) 100%
      );
      // opacity: 0.1;
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 0;
    }
  }
}

#works-images {
  flex-direction: column;
  gap: 0;
  // scroll-snap-type: y mandatory;
  // overflow-y: scroll;
  // height: 100vh;
  // height: 100svh;
  // scroll-behavior: smooth;

  // .single-project-hero {
  //   scroll-snap-align: start;
  // }
}
